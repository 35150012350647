@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.tooltip{
  visibility: hidden;
  position: absolute;
}
.has-tooltip:hover .tooltip {
  visibility: visible;
  z-index: 100;
}